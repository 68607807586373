// extracted by mini-css-extract-plugin
export var Qualities = "Qualities-module--Qualities--63vbn";
export var Qualities_header = "Qualities-module--Qualities_header--gyKnq";
export var Qualities_header_title = "Qualities-module--Qualities_header_title--9psKL";
export var container = "Qualities-module--container--ihV-C";
export var gatsbyImageWrapperConstrained = "Qualities-module--gatsby-image-wrapper-constrained--hUo8e";
export var gridSpan1010 = "Qualities-module--grid-span-10-10--1zWfb";
export var gridSpan1011 = "Qualities-module--grid-span-10-11--kcJ+3";
export var gridSpan102 = "Qualities-module--grid-span-10-2--p+r9N";
export var gridSpan103 = "Qualities-module--grid-span-10-3--wYysB";
export var gridSpan104 = "Qualities-module--grid-span-10-4--LXvUH";
export var gridSpan105 = "Qualities-module--grid-span-10-5--EBAhw";
export var gridSpan106 = "Qualities-module--grid-span-10-6--xIFhL";
export var gridSpan107 = "Qualities-module--grid-span-10-7--qW72e";
export var gridSpan108 = "Qualities-module--grid-span-10-8--YXR4H";
export var gridSpan109 = "Qualities-module--grid-span-10-9--7+E+p";
export var gridSpan110 = "Qualities-module--grid-span-1-10--EK3oc";
export var gridSpan111 = "Qualities-module--grid-span-1-11--FVLoW";
export var gridSpan1110 = "Qualities-module--grid-span-11-10--aKYID";
export var gridSpan1111 = "Qualities-module--grid-span-11-11--zHEaZ";
export var gridSpan112 = "Qualities-module--grid-span-11-2--smTiH";
export var gridSpan113 = "Qualities-module--grid-span-11-3--JdYyb";
export var gridSpan114 = "Qualities-module--grid-span-11-4--t2Pqb";
export var gridSpan115 = "Qualities-module--grid-span-11-5--Bu6w9";
export var gridSpan116 = "Qualities-module--grid-span-11-6--V5DYH";
export var gridSpan117 = "Qualities-module--grid-span-11-7--1kEWH";
export var gridSpan118 = "Qualities-module--grid-span-11-8--AxYAW";
export var gridSpan119 = "Qualities-module--grid-span-11-9--3VA2-";
export var gridSpan12 = "Qualities-module--grid-span-1-2--pjDGK";
export var gridSpan1210 = "Qualities-module--grid-span-12-10--bBrnX";
export var gridSpan1211 = "Qualities-module--grid-span-12-11--fDz2e";
export var gridSpan122 = "Qualities-module--grid-span-12-2--rhZ9+";
export var gridSpan123 = "Qualities-module--grid-span-12-3--6oGKY";
export var gridSpan124 = "Qualities-module--grid-span-12-4--aRgZH";
export var gridSpan125 = "Qualities-module--grid-span-12-5--GYbNd";
export var gridSpan126 = "Qualities-module--grid-span-12-6--HIqxR";
export var gridSpan127 = "Qualities-module--grid-span-12-7--Ow9FQ";
export var gridSpan128 = "Qualities-module--grid-span-12-8--C-f8B";
export var gridSpan129 = "Qualities-module--grid-span-12-9--wm+0r";
export var gridSpan13 = "Qualities-module--grid-span-1-3--k+thy";
export var gridSpan14 = "Qualities-module--grid-span-1-4--nGPEo";
export var gridSpan15 = "Qualities-module--grid-span-1-5--Bv2sJ";
export var gridSpan16 = "Qualities-module--grid-span-1-6--eVB3I";
export var gridSpan17 = "Qualities-module--grid-span-1-7--+jAd7";
export var gridSpan18 = "Qualities-module--grid-span-1-8--oXRLO";
export var gridSpan19 = "Qualities-module--grid-span-1-9--ZEXtj";
export var gridSpan210 = "Qualities-module--grid-span-2-10--1qKTT";
export var gridSpan211 = "Qualities-module--grid-span-2-11--TIDNM";
export var gridSpan22 = "Qualities-module--grid-span-2-2--oiBpe";
export var gridSpan23 = "Qualities-module--grid-span-2-3--isZeD";
export var gridSpan24 = "Qualities-module--grid-span-2-4--a0xZN";
export var gridSpan25 = "Qualities-module--grid-span-2-5--Xzla6";
export var gridSpan26 = "Qualities-module--grid-span-2-6--TTM2N";
export var gridSpan27 = "Qualities-module--grid-span-2-7--H+AqK";
export var gridSpan28 = "Qualities-module--grid-span-2-8--e1vhC";
export var gridSpan29 = "Qualities-module--grid-span-2-9--bMYsE";
export var gridSpan310 = "Qualities-module--grid-span-3-10--2rT7g";
export var gridSpan311 = "Qualities-module--grid-span-3-11--uTMLk";
export var gridSpan32 = "Qualities-module--grid-span-3-2--iHizF";
export var gridSpan33 = "Qualities-module--grid-span-3-3--HBST6";
export var gridSpan34 = "Qualities-module--grid-span-3-4--WJUPz";
export var gridSpan35 = "Qualities-module--grid-span-3-5--mQouq";
export var gridSpan36 = "Qualities-module--grid-span-3-6--9gxLY";
export var gridSpan37 = "Qualities-module--grid-span-3-7--ivhk2";
export var gridSpan38 = "Qualities-module--grid-span-3-8----OS1";
export var gridSpan39 = "Qualities-module--grid-span-3-9--DHqJV";
export var gridSpan410 = "Qualities-module--grid-span-4-10--aRrlm";
export var gridSpan411 = "Qualities-module--grid-span-4-11--Ue7Re";
export var gridSpan42 = "Qualities-module--grid-span-4-2--RVjdd";
export var gridSpan43 = "Qualities-module--grid-span-4-3--HXTNi";
export var gridSpan44 = "Qualities-module--grid-span-4-4---kyTX";
export var gridSpan45 = "Qualities-module--grid-span-4-5--hn5yI";
export var gridSpan46 = "Qualities-module--grid-span-4-6--8-eoI";
export var gridSpan47 = "Qualities-module--grid-span-4-7--2l3yO";
export var gridSpan48 = "Qualities-module--grid-span-4-8--s3H37";
export var gridSpan49 = "Qualities-module--grid-span-4-9---xkIX";
export var gridSpan510 = "Qualities-module--grid-span-5-10--qJkL6";
export var gridSpan511 = "Qualities-module--grid-span-5-11--jVduP";
export var gridSpan52 = "Qualities-module--grid-span-5-2--oB59f";
export var gridSpan53 = "Qualities-module--grid-span-5-3--zYo8v";
export var gridSpan54 = "Qualities-module--grid-span-5-4--uoDLA";
export var gridSpan55 = "Qualities-module--grid-span-5-5--hmIwd";
export var gridSpan56 = "Qualities-module--grid-span-5-6--KQ7+L";
export var gridSpan57 = "Qualities-module--grid-span-5-7--R6l7k";
export var gridSpan58 = "Qualities-module--grid-span-5-8--gN7Dr";
export var gridSpan59 = "Qualities-module--grid-span-5-9--5xpY6";
export var gridSpan610 = "Qualities-module--grid-span-6-10--EcbME";
export var gridSpan611 = "Qualities-module--grid-span-6-11--Reyif";
export var gridSpan62 = "Qualities-module--grid-span-6-2--pfp75";
export var gridSpan63 = "Qualities-module--grid-span-6-3--AYjBs";
export var gridSpan64 = "Qualities-module--grid-span-6-4--isnnZ";
export var gridSpan65 = "Qualities-module--grid-span-6-5--UKz4P";
export var gridSpan66 = "Qualities-module--grid-span-6-6--agHdw";
export var gridSpan67 = "Qualities-module--grid-span-6-7--64cKf";
export var gridSpan68 = "Qualities-module--grid-span-6-8--Eq554";
export var gridSpan69 = "Qualities-module--grid-span-6-9--pc0NN";
export var gridSpan710 = "Qualities-module--grid-span-7-10--xMpN4";
export var gridSpan711 = "Qualities-module--grid-span-7-11--wENDL";
export var gridSpan72 = "Qualities-module--grid-span-7-2--ZaNBf";
export var gridSpan73 = "Qualities-module--grid-span-7-3--S8Gvu";
export var gridSpan74 = "Qualities-module--grid-span-7-4--q1T9D";
export var gridSpan75 = "Qualities-module--grid-span-7-5--gnhMT";
export var gridSpan76 = "Qualities-module--grid-span-7-6--++gNO";
export var gridSpan77 = "Qualities-module--grid-span-7-7--fjzsP";
export var gridSpan78 = "Qualities-module--grid-span-7-8--76cWi";
export var gridSpan79 = "Qualities-module--grid-span-7-9--uPf4T";
export var gridSpan810 = "Qualities-module--grid-span-8-10--YVVBM";
export var gridSpan811 = "Qualities-module--grid-span-8-11--MPKdH";
export var gridSpan82 = "Qualities-module--grid-span-8-2--KWnKu";
export var gridSpan83 = "Qualities-module--grid-span-8-3--NGQZh";
export var gridSpan84 = "Qualities-module--grid-span-8-4--c+bsW";
export var gridSpan85 = "Qualities-module--grid-span-8-5--bT2Rc";
export var gridSpan86 = "Qualities-module--grid-span-8-6--yX1E-";
export var gridSpan87 = "Qualities-module--grid-span-8-7--u3Rkm";
export var gridSpan88 = "Qualities-module--grid-span-8-8--lEFuB";
export var gridSpan89 = "Qualities-module--grid-span-8-9--Kj6pG";
export var gridSpan910 = "Qualities-module--grid-span-9-10--HW5kF";
export var gridSpan911 = "Qualities-module--grid-span-9-11--wZmsu";
export var gridSpan92 = "Qualities-module--grid-span-9-2--sIo00";
export var gridSpan93 = "Qualities-module--grid-span-9-3--853Eq";
export var gridSpan94 = "Qualities-module--grid-span-9-4--m1qiK";
export var gridSpan95 = "Qualities-module--grid-span-9-5--TYxyT";
export var gridSpan96 = "Qualities-module--grid-span-9-6--b+ikX";
export var gridSpan97 = "Qualities-module--grid-span-9-7--w119b";
export var gridSpan98 = "Qualities-module--grid-span-9-8--Kpf4h";
export var gridSpan99 = "Qualities-module--grid-span-9-9--kJbQq";
export var textBreak = "Qualities-module--text-break---g7NV";
import React from "react";
import cx from "classnames";

import { Card } from "../../design-system";

import * as styles from "./Integrations.module.scss";

import { Sage, Slack, Zapier, QuickBooks } from "../../../assets/media/svgs";

const Integrations: React.FC = () => {
  return (
    <section className={styles.Integrations}>
      <div className={styles.Integrations_container}>
        <header>
          <h2 className={styles.Integrations_header_title}>
            Integrations that matter
          </h2>
          <p>
            Brass is seamlessly integrated with the tools your team is already
            familiar with.
          </p>
        </header>

        <div className={styles.Integrations_content}>
          <Card depth={0} customStyles={styles.Integrations_content_card}>
            <Slack />
            <h4 className={styles.Integrations_content_card_title}>Slack</h4>
            <p className={styles.Integrations_content_card_body}>
              Add Brass to your company’s slack to receive real-time
              notifications, send team mate invitations, and generate account
              statements.
            </p>
          </Card>
          <Card depth={0} customStyles={styles.Integrations_content_card}>
            <QuickBooks />
            <h4 className={styles.Integrations_content_card_title}>
              QuickBooks
            </h4>
            <p className={styles.Integrations_content_card_body}>
              Manage your business finance by syncing your invoices and payments
              with quickbooks.
            </p>
          </Card>
          <Card depth={0} customStyles={styles.Integrations_content_card}>
            <Sage />
            <h4 className={styles.Integrations_content_card_title}>Sage</h4>
            <p className={styles.Integrations_content_card_body}>
              Manage your business cashflow, invoices and activities by sync
              your account with sage.
            </p>
          </Card>
          <Card depth={0} customStyles={styles.Integrations_content_card}>
            <Zapier />
            <h4 className={styles.Integrations_content_card_title}>Zapier</h4>
            <p className={styles.Integrations_content_card_body}>
              Connect your Brass account to the thousands of apps on the zapier
              platform.
            </p>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Integrations;

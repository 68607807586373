import React from "react";

import { HOME_DATA } from "../../../data/pages";

import { QualitiesList } from "../../design-system";

import * as styles from "./Qualities.module.scss";

const Qualities: React.FC = () => {
  return (
    <section className={styles.Qualities}>
      <div>
        <header className={styles.Qualities_header}>
          <h2 className={styles.Qualities_header_title}>
            The complete financial stack for your business
          </h2>
          <p>
            Top-end financial tools to help your business with its financial
            operations and cash flow.
          </p>
          <p>
            We help companies of all types work better, save time and money with
            a simply better banking service that works.
          </p>
        </header>

        <QualitiesList items={HOME_DATA.QUALITIES} />
      </div>
    </section>
  );
};

export default Qualities;

import React from "react";
import cx from "classnames";
import { window } from "browser-monads";
import { StaticImage } from "gatsby-plugin-image";

import { HOME_DATA } from "data/pages";
import { useSetInterval } from "helpers/hooks/useSetInterval";

import * as styles from "./Testimonials.module.scss";

const Testimonials: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  if (window.innerWidth > 768) {
    useSetInterval(
      () =>
        setTabIndex((prev) =>
          prev === HOME_DATA.TESTIMONIALS.length - 1 ? 0 : prev + 1
        ),
      3000
    );
  }

  const { name, content, logo, image } = HOME_DATA.TESTIMONIALS[tabIndex];

  return (
    <section className={styles.Testimonials}>
      <div className={styles.Testimonials_container}>
        <header className={styles.Testimonials_header}>
          <h2 className={styles.Testimonials_header_title}>
            Built for today’s ambitious businesses
          </h2>
          <p>
            Thousands of forward-thinking businesses rely on Brass everyday to
            turbo-charge their business financial operations
          </p>
        </header>
      </div>
      <div className={styles.Testimonials_main}>
        <div className={styles.Testimonials_content}>
          <h3 className={styles.Testimonials_content_title}>“{content}”</h3>
          <p>{name}</p>

          <div className={styles.Testimonials_content_indicators}>
            {HOME_DATA.TESTIMONIALS.map((_, i) => (
              <span
                key={i}
                className={cx(
                  styles.Testimonials_content_indicator,
                  i === tabIndex && styles.Testimonials_content_indicator_active
                )}
                onClick={() => setTabIndex(i)}
              />
            ))}
          </div>

          <div className={styles.Testimonials_content_logo}>
            <img src={logo} alt="" />
          </div>
        </div>
        <div className={styles.Testimonials_image}>
          <img src={image} alt={name} />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

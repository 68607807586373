// extracted by mini-css-extract-plugin
export var Featues_item_desc = "Features-module--Featues_item_desc--ak7yH";
export var Features = "Features-module--Features--3m5k5";
export var Features_container = "Features-module--Features_container--38M1W";
export var Features_header = "Features-module--Features_header--7-TwM";
export var Features_header_title = "Features-module--Features_header_title--46mCJ";
export var Features_item = "Features-module--Features_item--iJpkh";
export var Features_item_desc = "Features-module--Features_item_desc--VWvqM";
export var Features_item_disabled = "Features-module--Features_item_disabled--GK9hL";
export var Features_item_selected = "Features-module--Features_item_selected--iPugx";
export var Features_item_title = "Features-module--Features_item_title--cKp4t";
export var Features_selector = "Features-module--Features_selector--nO-Ij";
export var Features_selector_selected = "Features-module--Features_selector_selected--UW5xN";
export var Features_selectors = "Features-module--Features_selectors--iPXqp";
export var Features_tabList = "Features-module--Features_tabList--11bNV";
export var Features_tab_panel = "Features-module--Features_tab_panel--BAN5b";
export var Features_tab_panel_selected = "Features-module--Features_tab_panel_selected--okbGh";
export var Features_tabs = "Features-module--Features_tabs--TDvSE";
export var container = "Features-module--container--I12RS";
export var gatsbyImageWrapperConstrained = "Features-module--gatsby-image-wrapper-constrained--YZ9Fi";
export var gridSpan1010 = "Features-module--grid-span-10-10--aBgOe";
export var gridSpan1011 = "Features-module--grid-span-10-11--6SMmv";
export var gridSpan102 = "Features-module--grid-span-10-2--4kQ0j";
export var gridSpan103 = "Features-module--grid-span-10-3--xlOok";
export var gridSpan104 = "Features-module--grid-span-10-4--R4Yw1";
export var gridSpan105 = "Features-module--grid-span-10-5--Xv7VA";
export var gridSpan106 = "Features-module--grid-span-10-6--iwWOC";
export var gridSpan107 = "Features-module--grid-span-10-7--weRo8";
export var gridSpan108 = "Features-module--grid-span-10-8--M+dRs";
export var gridSpan109 = "Features-module--grid-span-10-9--YbYRw";
export var gridSpan110 = "Features-module--grid-span-1-10--4fRBW";
export var gridSpan111 = "Features-module--grid-span-1-11--5yuqc";
export var gridSpan1110 = "Features-module--grid-span-11-10--cFrM7";
export var gridSpan1111 = "Features-module--grid-span-11-11--477wH";
export var gridSpan112 = "Features-module--grid-span-11-2--TfskL";
export var gridSpan113 = "Features-module--grid-span-11-3--uJM9Q";
export var gridSpan114 = "Features-module--grid-span-11-4--RDXSM";
export var gridSpan115 = "Features-module--grid-span-11-5--rlq5w";
export var gridSpan116 = "Features-module--grid-span-11-6--A8AZI";
export var gridSpan117 = "Features-module--grid-span-11-7--lN2Fq";
export var gridSpan118 = "Features-module--grid-span-11-8--Fwa+D";
export var gridSpan119 = "Features-module--grid-span-11-9--Zln4f";
export var gridSpan12 = "Features-module--grid-span-1-2--qMpSr";
export var gridSpan1210 = "Features-module--grid-span-12-10--M3qux";
export var gridSpan1211 = "Features-module--grid-span-12-11--aSW3a";
export var gridSpan122 = "Features-module--grid-span-12-2--mpmen";
export var gridSpan123 = "Features-module--grid-span-12-3--KfvBc";
export var gridSpan124 = "Features-module--grid-span-12-4--iJjxG";
export var gridSpan125 = "Features-module--grid-span-12-5--02l4S";
export var gridSpan126 = "Features-module--grid-span-12-6--tU5fL";
export var gridSpan127 = "Features-module--grid-span-12-7--upFps";
export var gridSpan128 = "Features-module--grid-span-12-8--2bGah";
export var gridSpan129 = "Features-module--grid-span-12-9--XFmGR";
export var gridSpan13 = "Features-module--grid-span-1-3--oSC2n";
export var gridSpan14 = "Features-module--grid-span-1-4--0x99n";
export var gridSpan15 = "Features-module--grid-span-1-5--BztFh";
export var gridSpan16 = "Features-module--grid-span-1-6--txkV6";
export var gridSpan17 = "Features-module--grid-span-1-7--MIdTL";
export var gridSpan18 = "Features-module--grid-span-1-8--UZuZg";
export var gridSpan19 = "Features-module--grid-span-1-9--kl4z5";
export var gridSpan210 = "Features-module--grid-span-2-10--GhiTQ";
export var gridSpan211 = "Features-module--grid-span-2-11--iNTbR";
export var gridSpan22 = "Features-module--grid-span-2-2--DTraM";
export var gridSpan23 = "Features-module--grid-span-2-3--tP6bk";
export var gridSpan24 = "Features-module--grid-span-2-4--ab-Ib";
export var gridSpan25 = "Features-module--grid-span-2-5--OzrSf";
export var gridSpan26 = "Features-module--grid-span-2-6--RXHTB";
export var gridSpan27 = "Features-module--grid-span-2-7--5JNZw";
export var gridSpan28 = "Features-module--grid-span-2-8--ZWxX9";
export var gridSpan29 = "Features-module--grid-span-2-9--xFdM1";
export var gridSpan310 = "Features-module--grid-span-3-10--Oe-Lv";
export var gridSpan311 = "Features-module--grid-span-3-11--C+SL4";
export var gridSpan32 = "Features-module--grid-span-3-2--aLAq0";
export var gridSpan33 = "Features-module--grid-span-3-3--T8NOt";
export var gridSpan34 = "Features-module--grid-span-3-4--XOXV3";
export var gridSpan35 = "Features-module--grid-span-3-5--wQSgU";
export var gridSpan36 = "Features-module--grid-span-3-6--ULoDE";
export var gridSpan37 = "Features-module--grid-span-3-7--uEUIn";
export var gridSpan38 = "Features-module--grid-span-3-8--nIDfM";
export var gridSpan39 = "Features-module--grid-span-3-9--56ild";
export var gridSpan410 = "Features-module--grid-span-4-10---vlED";
export var gridSpan411 = "Features-module--grid-span-4-11--SwVwn";
export var gridSpan42 = "Features-module--grid-span-4-2--IZOli";
export var gridSpan43 = "Features-module--grid-span-4-3--6MXeh";
export var gridSpan44 = "Features-module--grid-span-4-4--rn+Rh";
export var gridSpan45 = "Features-module--grid-span-4-5--VdeuN";
export var gridSpan46 = "Features-module--grid-span-4-6--5dc-t";
export var gridSpan47 = "Features-module--grid-span-4-7--mARde";
export var gridSpan48 = "Features-module--grid-span-4-8--le77J";
export var gridSpan49 = "Features-module--grid-span-4-9--NpGuv";
export var gridSpan510 = "Features-module--grid-span-5-10--9v5iz";
export var gridSpan511 = "Features-module--grid-span-5-11--TBvBs";
export var gridSpan52 = "Features-module--grid-span-5-2--RW138";
export var gridSpan53 = "Features-module--grid-span-5-3--cLQ8V";
export var gridSpan54 = "Features-module--grid-span-5-4--CTYMO";
export var gridSpan55 = "Features-module--grid-span-5-5--yrjo7";
export var gridSpan56 = "Features-module--grid-span-5-6--QjJml";
export var gridSpan57 = "Features-module--grid-span-5-7--pJwgv";
export var gridSpan58 = "Features-module--grid-span-5-8--0uiLX";
export var gridSpan59 = "Features-module--grid-span-5-9--LvBMF";
export var gridSpan610 = "Features-module--grid-span-6-10--kDwOG";
export var gridSpan611 = "Features-module--grid-span-6-11--T8qev";
export var gridSpan62 = "Features-module--grid-span-6-2--ymFYV";
export var gridSpan63 = "Features-module--grid-span-6-3--Av1VD";
export var gridSpan64 = "Features-module--grid-span-6-4--xxoLD";
export var gridSpan65 = "Features-module--grid-span-6-5--E3PLg";
export var gridSpan66 = "Features-module--grid-span-6-6--4ha8C";
export var gridSpan67 = "Features-module--grid-span-6-7--+yWUb";
export var gridSpan68 = "Features-module--grid-span-6-8--UfV02";
export var gridSpan69 = "Features-module--grid-span-6-9--ei8Em";
export var gridSpan710 = "Features-module--grid-span-7-10--XNN8h";
export var gridSpan711 = "Features-module--grid-span-7-11--Ep9Wt";
export var gridSpan72 = "Features-module--grid-span-7-2--Y1Qrl";
export var gridSpan73 = "Features-module--grid-span-7-3--RCbDV";
export var gridSpan74 = "Features-module--grid-span-7-4--15mpl";
export var gridSpan75 = "Features-module--grid-span-7-5--L5gMG";
export var gridSpan76 = "Features-module--grid-span-7-6--no-U9";
export var gridSpan77 = "Features-module--grid-span-7-7--vQJhE";
export var gridSpan78 = "Features-module--grid-span-7-8--DT+in";
export var gridSpan79 = "Features-module--grid-span-7-9--WJAVO";
export var gridSpan810 = "Features-module--grid-span-8-10--7caET";
export var gridSpan811 = "Features-module--grid-span-8-11--LrJ1C";
export var gridSpan82 = "Features-module--grid-span-8-2--EL2pz";
export var gridSpan83 = "Features-module--grid-span-8-3--jORRs";
export var gridSpan84 = "Features-module--grid-span-8-4--xk+Fp";
export var gridSpan85 = "Features-module--grid-span-8-5--t4t7j";
export var gridSpan86 = "Features-module--grid-span-8-6--7We5d";
export var gridSpan87 = "Features-module--grid-span-8-7--7xAfH";
export var gridSpan88 = "Features-module--grid-span-8-8--e8wzZ";
export var gridSpan89 = "Features-module--grid-span-8-9--M9CRn";
export var gridSpan910 = "Features-module--grid-span-9-10--zf2a3";
export var gridSpan911 = "Features-module--grid-span-9-11--KmFop";
export var gridSpan92 = "Features-module--grid-span-9-2--Hd59f";
export var gridSpan93 = "Features-module--grid-span-9-3--n3t50";
export var gridSpan94 = "Features-module--grid-span-9-4--GYoWJ";
export var gridSpan95 = "Features-module--grid-span-9-5--8bor4";
export var gridSpan96 = "Features-module--grid-span-9-6--o-u++";
export var gridSpan97 = "Features-module--grid-span-9-7--RInRT";
export var gridSpan98 = "Features-module--grid-span-9-8--nv2z4";
export var gridSpan99 = "Features-module--grid-span-9-9--IIciZ";
export var textBreak = "Features-module--text-break--MYRIL";
// extracted by mini-css-extract-plugin
export var Integrations = "Integrations-module--Integrations--NAXE4";
export var Integrations_container = "Integrations-module--Integrations_container--y4y5J";
export var Integrations_content = "Integrations-module--Integrations_content--6N91E";
export var Integrations_content_card = "Integrations-module--Integrations_content_card--KLvLw";
export var Integrations_content_card_body = "Integrations-module--Integrations_content_card_body--9qkoG";
export var Integrations_content_card_title = "Integrations-module--Integrations_content_card_title--MDlZ8";
export var Integrations_header_title = "Integrations-module--Integrations_header_title--zR9Xr";
export var container = "Integrations-module--container--yzE9b";
export var gatsbyImageWrapperConstrained = "Integrations-module--gatsby-image-wrapper-constrained--Wq9b2";
export var gridSpan1010 = "Integrations-module--grid-span-10-10---63bP";
export var gridSpan1011 = "Integrations-module--grid-span-10-11--zvgX-";
export var gridSpan102 = "Integrations-module--grid-span-10-2--qFR-2";
export var gridSpan103 = "Integrations-module--grid-span-10-3--UvUos";
export var gridSpan104 = "Integrations-module--grid-span-10-4--fzxSt";
export var gridSpan105 = "Integrations-module--grid-span-10-5--D6QzU";
export var gridSpan106 = "Integrations-module--grid-span-10-6--POkpC";
export var gridSpan107 = "Integrations-module--grid-span-10-7--0jcOU";
export var gridSpan108 = "Integrations-module--grid-span-10-8--IBFQb";
export var gridSpan109 = "Integrations-module--grid-span-10-9--3fUbU";
export var gridSpan110 = "Integrations-module--grid-span-1-10--+TZMA";
export var gridSpan111 = "Integrations-module--grid-span-1-11--bMMvl";
export var gridSpan1110 = "Integrations-module--grid-span-11-10--KpHCC";
export var gridSpan1111 = "Integrations-module--grid-span-11-11--CT2iA";
export var gridSpan112 = "Integrations-module--grid-span-11-2--OZVkP";
export var gridSpan113 = "Integrations-module--grid-span-11-3--LjcKS";
export var gridSpan114 = "Integrations-module--grid-span-11-4--xrp7q";
export var gridSpan115 = "Integrations-module--grid-span-11-5--12AEB";
export var gridSpan116 = "Integrations-module--grid-span-11-6--JVPLL";
export var gridSpan117 = "Integrations-module--grid-span-11-7--AESnj";
export var gridSpan118 = "Integrations-module--grid-span-11-8--AjDhE";
export var gridSpan119 = "Integrations-module--grid-span-11-9--b-yeG";
export var gridSpan12 = "Integrations-module--grid-span-1-2--9iEUF";
export var gridSpan1210 = "Integrations-module--grid-span-12-10--mCDr3";
export var gridSpan1211 = "Integrations-module--grid-span-12-11--0kNjB";
export var gridSpan122 = "Integrations-module--grid-span-12-2--YsheV";
export var gridSpan123 = "Integrations-module--grid-span-12-3--bFKzT";
export var gridSpan124 = "Integrations-module--grid-span-12-4--OdYVp";
export var gridSpan125 = "Integrations-module--grid-span-12-5--KaPlZ";
export var gridSpan126 = "Integrations-module--grid-span-12-6--cycfS";
export var gridSpan127 = "Integrations-module--grid-span-12-7--QyGFz";
export var gridSpan128 = "Integrations-module--grid-span-12-8--9Q5em";
export var gridSpan129 = "Integrations-module--grid-span-12-9--6VMxR";
export var gridSpan13 = "Integrations-module--grid-span-1-3--Ge8l2";
export var gridSpan14 = "Integrations-module--grid-span-1-4--Cshnr";
export var gridSpan15 = "Integrations-module--grid-span-1-5--AUA59";
export var gridSpan16 = "Integrations-module--grid-span-1-6--jHf-a";
export var gridSpan17 = "Integrations-module--grid-span-1-7--t6ikQ";
export var gridSpan18 = "Integrations-module--grid-span-1-8--0etga";
export var gridSpan19 = "Integrations-module--grid-span-1-9--uJ-OU";
export var gridSpan210 = "Integrations-module--grid-span-2-10--R+zh0";
export var gridSpan211 = "Integrations-module--grid-span-2-11--uwqmn";
export var gridSpan22 = "Integrations-module--grid-span-2-2--qw+bz";
export var gridSpan23 = "Integrations-module--grid-span-2-3--lLuLE";
export var gridSpan24 = "Integrations-module--grid-span-2-4--oyyuz";
export var gridSpan25 = "Integrations-module--grid-span-2-5--ALiht";
export var gridSpan26 = "Integrations-module--grid-span-2-6--Umh0p";
export var gridSpan27 = "Integrations-module--grid-span-2-7--lZyW0";
export var gridSpan28 = "Integrations-module--grid-span-2-8--mdvyP";
export var gridSpan29 = "Integrations-module--grid-span-2-9--0jGv+";
export var gridSpan310 = "Integrations-module--grid-span-3-10--qpQg2";
export var gridSpan311 = "Integrations-module--grid-span-3-11--Nt8EU";
export var gridSpan32 = "Integrations-module--grid-span-3-2--Bbemb";
export var gridSpan33 = "Integrations-module--grid-span-3-3--tIQBS";
export var gridSpan34 = "Integrations-module--grid-span-3-4--+SWOa";
export var gridSpan35 = "Integrations-module--grid-span-3-5--T3AT0";
export var gridSpan36 = "Integrations-module--grid-span-3-6--1j1f5";
export var gridSpan37 = "Integrations-module--grid-span-3-7--22t9x";
export var gridSpan38 = "Integrations-module--grid-span-3-8--DQcpC";
export var gridSpan39 = "Integrations-module--grid-span-3-9--dDsUf";
export var gridSpan410 = "Integrations-module--grid-span-4-10--PL8YM";
export var gridSpan411 = "Integrations-module--grid-span-4-11--I4K1Z";
export var gridSpan42 = "Integrations-module--grid-span-4-2--HVwVM";
export var gridSpan43 = "Integrations-module--grid-span-4-3--c6k0O";
export var gridSpan44 = "Integrations-module--grid-span-4-4--chV-6";
export var gridSpan45 = "Integrations-module--grid-span-4-5--cxVcR";
export var gridSpan46 = "Integrations-module--grid-span-4-6--RiR29";
export var gridSpan47 = "Integrations-module--grid-span-4-7--bKeqs";
export var gridSpan48 = "Integrations-module--grid-span-4-8--UdqJ7";
export var gridSpan49 = "Integrations-module--grid-span-4-9--O3tBu";
export var gridSpan510 = "Integrations-module--grid-span-5-10--FlU+c";
export var gridSpan511 = "Integrations-module--grid-span-5-11--uQs-V";
export var gridSpan52 = "Integrations-module--grid-span-5-2--boOEA";
export var gridSpan53 = "Integrations-module--grid-span-5-3--+Ic5F";
export var gridSpan54 = "Integrations-module--grid-span-5-4--KMXD3";
export var gridSpan55 = "Integrations-module--grid-span-5-5--Mqr4P";
export var gridSpan56 = "Integrations-module--grid-span-5-6--tVUaG";
export var gridSpan57 = "Integrations-module--grid-span-5-7--fahy-";
export var gridSpan58 = "Integrations-module--grid-span-5-8--fTQjm";
export var gridSpan59 = "Integrations-module--grid-span-5-9--dliFv";
export var gridSpan610 = "Integrations-module--grid-span-6-10--ESf+u";
export var gridSpan611 = "Integrations-module--grid-span-6-11--XOH+q";
export var gridSpan62 = "Integrations-module--grid-span-6-2--yRmjd";
export var gridSpan63 = "Integrations-module--grid-span-6-3--Ccpnd";
export var gridSpan64 = "Integrations-module--grid-span-6-4--aoKkl";
export var gridSpan65 = "Integrations-module--grid-span-6-5--F7C+i";
export var gridSpan66 = "Integrations-module--grid-span-6-6--lIgwf";
export var gridSpan67 = "Integrations-module--grid-span-6-7--0sRDc";
export var gridSpan68 = "Integrations-module--grid-span-6-8--Hgh8w";
export var gridSpan69 = "Integrations-module--grid-span-6-9--qk9Sc";
export var gridSpan710 = "Integrations-module--grid-span-7-10--0UrOY";
export var gridSpan711 = "Integrations-module--grid-span-7-11--1ypG3";
export var gridSpan72 = "Integrations-module--grid-span-7-2--X8Nrz";
export var gridSpan73 = "Integrations-module--grid-span-7-3--LhMXU";
export var gridSpan74 = "Integrations-module--grid-span-7-4--k588E";
export var gridSpan75 = "Integrations-module--grid-span-7-5--821fP";
export var gridSpan76 = "Integrations-module--grid-span-7-6--UVLKN";
export var gridSpan77 = "Integrations-module--grid-span-7-7--XlTlH";
export var gridSpan78 = "Integrations-module--grid-span-7-8--th7Tn";
export var gridSpan79 = "Integrations-module--grid-span-7-9--KRnrQ";
export var gridSpan810 = "Integrations-module--grid-span-8-10--BUCDY";
export var gridSpan811 = "Integrations-module--grid-span-8-11--qnZ99";
export var gridSpan82 = "Integrations-module--grid-span-8-2--K5fss";
export var gridSpan83 = "Integrations-module--grid-span-8-3--EdgsR";
export var gridSpan84 = "Integrations-module--grid-span-8-4--8Dmjh";
export var gridSpan85 = "Integrations-module--grid-span-8-5--VGP8u";
export var gridSpan86 = "Integrations-module--grid-span-8-6--+DYZz";
export var gridSpan87 = "Integrations-module--grid-span-8-7--ZfbMh";
export var gridSpan88 = "Integrations-module--grid-span-8-8--Nkf5N";
export var gridSpan89 = "Integrations-module--grid-span-8-9--8a+FP";
export var gridSpan910 = "Integrations-module--grid-span-9-10--y8n+m";
export var gridSpan911 = "Integrations-module--grid-span-9-11--JQfdw";
export var gridSpan92 = "Integrations-module--grid-span-9-2--D+eiT";
export var gridSpan93 = "Integrations-module--grid-span-9-3--zpSo6";
export var gridSpan94 = "Integrations-module--grid-span-9-4--A2zq6";
export var gridSpan95 = "Integrations-module--grid-span-9-5--lEmAs";
export var gridSpan96 = "Integrations-module--grid-span-9-6--sxG3B";
export var gridSpan97 = "Integrations-module--grid-span-9-7--wTMmw";
export var gridSpan98 = "Integrations-module--grid-span-9-8--xCrdh";
export var gridSpan99 = "Integrations-module--grid-span-9-9--S3nuD";
export var textBreak = "Integrations-module--text-break--MCjqr";
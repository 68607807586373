import React from "react";
import cx from "classnames";
import { HOME_DATA } from "data/pages";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import * as styles from "./Features.module.scss";

const Features: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <section className={styles.Features}>
      <div className={styles.Features_container}>
        <header className={styles.Features_header}>
          <h2 className={styles.Features_header_title}>
            Everything you need, less of what you don't.
          </h2>
        </header>
        <Tabs
          selectedIndex={tabIndex}
          className={styles.Features_tabs}
          onSelect={(index: number) => setTabIndex(index)}
          selectedTabClassName={styles.Features_item_selected}
          selectedTabPanelClassName={styles.Features_tab_panel_selected}
        >
          <TabList className={styles.Features_tabList}>
            {HOME_DATA.FEATURES.map((feature, index) => (
              <Tab
                className={styles.Features_item}
                disabledClassName={styles.Features_item_disabled}
                key={index}
              >
                <h3 className={styles.Features_item_title}>{feature.title}</h3>
                <p className={styles.Features_item_desc}>
                  {feature.description}
                </p>
              </Tab>
            ))}
          </TabList>

          <div className={styles.Features_selectors}>
            {HOME_DATA.FEATURES.map((feature, index) => (
              <div
                className={cx(styles.Features_selector, {
                  [styles.Features_selector_selected]: index === tabIndex,
                })}
                onClick={() => setTabIndex(index)}
                key={index}
              ></div>
            ))}
          </div>

          {/* To be reviewed */}
          {HOME_DATA.FEATURES.map((feature, index) => {
            return (
              <TabPanel key={index} className={styles.Features_tab_panel}>
                <video autoPlay={true} loop muted src={feature.media} />
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};

export default Features;

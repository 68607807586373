// extracted by mini-css-extract-plugin
export var Testimonials = "Testimonials-module--Testimonials--ybPnh";
export var Testimonials_container = "Testimonials-module--Testimonials_container--sG2YN";
export var Testimonials_content = "Testimonials-module--Testimonials_content--rWHU4";
export var Testimonials_content_indicator = "Testimonials-module--Testimonials_content_indicator--FSkc2";
export var Testimonials_content_indicator_active = "Testimonials-module--Testimonials_content_indicator_active--9LSlf";
export var Testimonials_content_indicators = "Testimonials-module--Testimonials_content_indicators--GIrKl";
export var Testimonials_content_logo = "Testimonials-module--Testimonials_content_logo--ZEJAI";
export var Testimonials_content_title = "Testimonials-module--Testimonials_content_title--CSTvX";
export var Testimonials_header = "Testimonials-module--Testimonials_header--YBbnm";
export var Testimonials_header_title = "Testimonials-module--Testimonials_header_title--5j4yY";
export var Testimonials_image = "Testimonials-module--Testimonials_image--Vxscn";
export var Testimonials_main = "Testimonials-module--Testimonials_main--TbGSH";
export var container = "Testimonials-module--container--5MiXu";
export var gatsbyImageWrapperConstrained = "Testimonials-module--gatsby-image-wrapper-constrained--NLix5";
export var gridSpan1010 = "Testimonials-module--grid-span-10-10--JyyqK";
export var gridSpan1011 = "Testimonials-module--grid-span-10-11--ec1vE";
export var gridSpan102 = "Testimonials-module--grid-span-10-2--XXDUO";
export var gridSpan103 = "Testimonials-module--grid-span-10-3--Uo1vq";
export var gridSpan104 = "Testimonials-module--grid-span-10-4--C0CEc";
export var gridSpan105 = "Testimonials-module--grid-span-10-5--qkl5L";
export var gridSpan106 = "Testimonials-module--grid-span-10-6--rbwxy";
export var gridSpan107 = "Testimonials-module--grid-span-10-7--RkewO";
export var gridSpan108 = "Testimonials-module--grid-span-10-8--RkTk2";
export var gridSpan109 = "Testimonials-module--grid-span-10-9--qliEV";
export var gridSpan110 = "Testimonials-module--grid-span-1-10--kjlpd";
export var gridSpan111 = "Testimonials-module--grid-span-1-11--PoguO";
export var gridSpan1110 = "Testimonials-module--grid-span-11-10--XCq0H";
export var gridSpan1111 = "Testimonials-module--grid-span-11-11--zM2NF";
export var gridSpan112 = "Testimonials-module--grid-span-11-2--bYWfr";
export var gridSpan113 = "Testimonials-module--grid-span-11-3--uws16";
export var gridSpan114 = "Testimonials-module--grid-span-11-4--7A9yR";
export var gridSpan115 = "Testimonials-module--grid-span-11-5--rn4K8";
export var gridSpan116 = "Testimonials-module--grid-span-11-6--8Msbk";
export var gridSpan117 = "Testimonials-module--grid-span-11-7--idjek";
export var gridSpan118 = "Testimonials-module--grid-span-11-8--M6zmF";
export var gridSpan119 = "Testimonials-module--grid-span-11-9--vLBrN";
export var gridSpan12 = "Testimonials-module--grid-span-1-2--9e7sY";
export var gridSpan1210 = "Testimonials-module--grid-span-12-10--Fmi3c";
export var gridSpan1211 = "Testimonials-module--grid-span-12-11--7uq-J";
export var gridSpan122 = "Testimonials-module--grid-span-12-2--dY2im";
export var gridSpan123 = "Testimonials-module--grid-span-12-3--8lin-";
export var gridSpan124 = "Testimonials-module--grid-span-12-4--PDSGH";
export var gridSpan125 = "Testimonials-module--grid-span-12-5--JD1T8";
export var gridSpan126 = "Testimonials-module--grid-span-12-6--Sn1eY";
export var gridSpan127 = "Testimonials-module--grid-span-12-7--hr2vK";
export var gridSpan128 = "Testimonials-module--grid-span-12-8--YHGUU";
export var gridSpan129 = "Testimonials-module--grid-span-12-9--+QY-3";
export var gridSpan13 = "Testimonials-module--grid-span-1-3--ObYqE";
export var gridSpan14 = "Testimonials-module--grid-span-1-4--EBMdF";
export var gridSpan15 = "Testimonials-module--grid-span-1-5--crq98";
export var gridSpan16 = "Testimonials-module--grid-span-1-6--gAJYz";
export var gridSpan17 = "Testimonials-module--grid-span-1-7--36iwn";
export var gridSpan18 = "Testimonials-module--grid-span-1-8--WX2eC";
export var gridSpan19 = "Testimonials-module--grid-span-1-9--SHjzs";
export var gridSpan210 = "Testimonials-module--grid-span-2-10--Mu1y7";
export var gridSpan211 = "Testimonials-module--grid-span-2-11--K7VBn";
export var gridSpan22 = "Testimonials-module--grid-span-2-2--nYih4";
export var gridSpan23 = "Testimonials-module--grid-span-2-3--QkIVr";
export var gridSpan24 = "Testimonials-module--grid-span-2-4--8TEM9";
export var gridSpan25 = "Testimonials-module--grid-span-2-5--9oTmt";
export var gridSpan26 = "Testimonials-module--grid-span-2-6--tBiFG";
export var gridSpan27 = "Testimonials-module--grid-span-2-7--5F+TS";
export var gridSpan28 = "Testimonials-module--grid-span-2-8--ur-2L";
export var gridSpan29 = "Testimonials-module--grid-span-2-9--zYXZ5";
export var gridSpan310 = "Testimonials-module--grid-span-3-10--jfaed";
export var gridSpan311 = "Testimonials-module--grid-span-3-11--ixDn2";
export var gridSpan32 = "Testimonials-module--grid-span-3-2--d7XbA";
export var gridSpan33 = "Testimonials-module--grid-span-3-3--iLS5j";
export var gridSpan34 = "Testimonials-module--grid-span-3-4--L9bbD";
export var gridSpan35 = "Testimonials-module--grid-span-3-5--PqELI";
export var gridSpan36 = "Testimonials-module--grid-span-3-6--ZJ0Uc";
export var gridSpan37 = "Testimonials-module--grid-span-3-7--R8rGB";
export var gridSpan38 = "Testimonials-module--grid-span-3-8--nIVAa";
export var gridSpan39 = "Testimonials-module--grid-span-3-9--sdvAA";
export var gridSpan410 = "Testimonials-module--grid-span-4-10--DVDsL";
export var gridSpan411 = "Testimonials-module--grid-span-4-11--hfMIt";
export var gridSpan42 = "Testimonials-module--grid-span-4-2--SWbdp";
export var gridSpan43 = "Testimonials-module--grid-span-4-3--Qcc8K";
export var gridSpan44 = "Testimonials-module--grid-span-4-4--3TKh1";
export var gridSpan45 = "Testimonials-module--grid-span-4-5--PMAhX";
export var gridSpan46 = "Testimonials-module--grid-span-4-6--aYp49";
export var gridSpan47 = "Testimonials-module--grid-span-4-7--IX7h0";
export var gridSpan48 = "Testimonials-module--grid-span-4-8--sPtnU";
export var gridSpan49 = "Testimonials-module--grid-span-4-9--LJpsi";
export var gridSpan510 = "Testimonials-module--grid-span-5-10--dAlPA";
export var gridSpan511 = "Testimonials-module--grid-span-5-11--VBTt1";
export var gridSpan52 = "Testimonials-module--grid-span-5-2--3qUWR";
export var gridSpan53 = "Testimonials-module--grid-span-5-3--DJQOd";
export var gridSpan54 = "Testimonials-module--grid-span-5-4--qLs3a";
export var gridSpan55 = "Testimonials-module--grid-span-5-5--jd+Nh";
export var gridSpan56 = "Testimonials-module--grid-span-5-6--FEtO-";
export var gridSpan57 = "Testimonials-module--grid-span-5-7--VSJNU";
export var gridSpan58 = "Testimonials-module--grid-span-5-8--r8M3F";
export var gridSpan59 = "Testimonials-module--grid-span-5-9--EyLgZ";
export var gridSpan610 = "Testimonials-module--grid-span-6-10--Ei2bo";
export var gridSpan611 = "Testimonials-module--grid-span-6-11--AsleL";
export var gridSpan62 = "Testimonials-module--grid-span-6-2--8qQjX";
export var gridSpan63 = "Testimonials-module--grid-span-6-3--psUNk";
export var gridSpan64 = "Testimonials-module--grid-span-6-4--aCNfa";
export var gridSpan65 = "Testimonials-module--grid-span-6-5--vUs5w";
export var gridSpan66 = "Testimonials-module--grid-span-6-6--28d-d";
export var gridSpan67 = "Testimonials-module--grid-span-6-7--anoj2";
export var gridSpan68 = "Testimonials-module--grid-span-6-8--ijwzw";
export var gridSpan69 = "Testimonials-module--grid-span-6-9--V-66+";
export var gridSpan710 = "Testimonials-module--grid-span-7-10--is1Q9";
export var gridSpan711 = "Testimonials-module--grid-span-7-11--9+xwJ";
export var gridSpan72 = "Testimonials-module--grid-span-7-2--daO3G";
export var gridSpan73 = "Testimonials-module--grid-span-7-3--nBrfX";
export var gridSpan74 = "Testimonials-module--grid-span-7-4--LlGEj";
export var gridSpan75 = "Testimonials-module--grid-span-7-5--tM1ub";
export var gridSpan76 = "Testimonials-module--grid-span-7-6--3AYL4";
export var gridSpan77 = "Testimonials-module--grid-span-7-7--OkbL+";
export var gridSpan78 = "Testimonials-module--grid-span-7-8--y0ZoY";
export var gridSpan79 = "Testimonials-module--grid-span-7-9--gpQD7";
export var gridSpan810 = "Testimonials-module--grid-span-8-10--1gK5h";
export var gridSpan811 = "Testimonials-module--grid-span-8-11--f11rb";
export var gridSpan82 = "Testimonials-module--grid-span-8-2--BdksQ";
export var gridSpan83 = "Testimonials-module--grid-span-8-3--xuZpX";
export var gridSpan84 = "Testimonials-module--grid-span-8-4--bcrNP";
export var gridSpan85 = "Testimonials-module--grid-span-8-5--p18j5";
export var gridSpan86 = "Testimonials-module--grid-span-8-6--8Re7Z";
export var gridSpan87 = "Testimonials-module--grid-span-8-7--RtBhB";
export var gridSpan88 = "Testimonials-module--grid-span-8-8--5uhuA";
export var gridSpan89 = "Testimonials-module--grid-span-8-9--pz2Fj";
export var gridSpan910 = "Testimonials-module--grid-span-9-10--r9JrY";
export var gridSpan911 = "Testimonials-module--grid-span-9-11--Ht8ye";
export var gridSpan92 = "Testimonials-module--grid-span-9-2--LjZkh";
export var gridSpan93 = "Testimonials-module--grid-span-9-3--R1ssD";
export var gridSpan94 = "Testimonials-module--grid-span-9-4--Bu8x6";
export var gridSpan95 = "Testimonials-module--grid-span-9-5--etByO";
export var gridSpan96 = "Testimonials-module--grid-span-9-6--FusDx";
export var gridSpan97 = "Testimonials-module--grid-span-9-7--kf4nb";
export var gridSpan98 = "Testimonials-module--grid-span-9-8--H6ul4";
export var gridSpan99 = "Testimonials-module--grid-span-9-9--30Lt0";
export var textBreak = "Testimonials-module--text-break--PMb7-";